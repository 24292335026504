<template>
  <div>
    <div :class="['service',{ 'showService': isshow }]">
      <div :class="['service-logo',{ 'showServiceLogo': isshow }]" @click="handleClick">
        <img src="@/assets/image//nav/customer-service.png" alt="" srcset="">
      </div>
      <span :class="{ 'serviceText': isshow }">聯係客服</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "customerService",
  data () {
    return {
      isshow: false
    }
  },
  methods: {
    handleClick () {
      this.isshow = !this.isshow
    }
  }

}
</script>
<style lang="scss" scoped>
.service {
  position: fixed;
  right: 16px;
  bottom: 141px;
  background: #90D12E;
  border-radius: 20px 20px 20px 20px;
  display: flex;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 2px;
  box-sizing: border-box;
  transition: all 0.1s;
  overflow: hidden;

  &.showService {
    width: 118px;
    box-shadow: 0px 4px 24px 0px rgba(92, 96, 113, 0.4);
    border-radius: 20px 20px 20px 20px;
  }

  .service-logo {
    width: 38px;
    height: 38px;
    box-sizing: border-box;
    background: #90D12E;
    font-size: 0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.1s;

    &.showServiceLogo {
      background: #FFFFFF;
    }

    img {
      width: 36px;
      height: 36px;
    }
  }

  span {
    margin-left: 8px;
    background: #90D12E;
    color: #ffffff;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    text-align: left;
    opacity: 0;
    transition: all 1s;

    &.serviceText {
      opacity: 1;
    }
  }

}
</style>