<template>
  <div>
    <div id="myModal" class="modal" @touchmove.prevent :style="{ display: isHidden ? 'block' : 'none' }">

      <!-- 弹窗内容 -->
      <div class="modal-content">

        <div class="modal-body">
          <img src="@/assets/image/advert.png" alt="" srcset="">
        </div>
        <div class="modal-footer">
          <img class="close" @click="handleClose" src="@/assets/image/close.png" alt="" srcset="">
        </div>
      </div>

    </div>
  </div>
</template>
<script>
export default {
  name: "advertPop",

  data () {
    return {
      isHidden: false
    }
  },
  methods: {
    open () {
      this.isHidden = !this.isHidden
      if (this.isHidden) {
        document.documentElement.style.overflowY = 'hidden'
      } else {
        document.documentElement.style.overflowY = 'auto' //取消禁止屏幕滚动
      }
    },
    handleClose () {
      this.isHidden = false
    }
  }

}
</script>
<style lang="scss" scoped>
/* 弹窗 */
.modal {
  display: none;
  /* 默认隐藏 */
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-animation-name: fadeIn;
  -webkit-animation-duration: 0.4s;
  animation-name: fadeIn;
  animation-duration: 0.4s
}

/* 弹窗内容 */
.modal-content {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-animation-name: slideIn;
  -webkit-animation-duration: 0.4s;
  animation-name: slideIn;
  animation-duration: 0.4s
}

/* 关闭按钮 */
.close {
  color: #000;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-header {
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white;
}

.modal-body {
  width: 320px;
  height: 426px;
  font-size: 0;

  img {
    width: 100%;
    height: 100%;
  }
}

.modal-footer {
  margin-top: 29px;
  font-size: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .close {
    width: 40px;
    height: 40px;
  }
}

/* 添加动画 */
@-webkit-keyframes slideIn {
  from {
    bottom: -300px;
    opacity: 0
  }

  to {
    bottom: 0;
    opacity: 1
  }
}

@keyframes slideIn {
  from {
    bottom: -300px;
    opacity: 0
  }

  to {
    bottom: 0;
    opacity: 1
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

@keyframes fadeIn {
  from {
    opacity: 0
  }

  to {
    opacity: 1
  }
}
</style>