<!--
 * @version: v 1.0.0
 * @Github: https://github.com/GitHubGanKai
 * @Author: GitHubGanKai
 * @Date: 2021-01-01 17:19:50
 * @LastEditors: gankai
 * @LastEditTime: 2021-01-02 20:33:24
 * @FilePath: /refactor-with-vue3/src/components/popup/index.vue
-->
<template>
  <div class="popup" v-show="popupShow">
    <div class="popup-content">
      <div class="message">
        <span class="title">{{ popupTitle }}</span>
        <img class="close" @click="cancelPopup" src="@/assets/icons/ic_Close@2x.png">
      </div>
      <div class="content" v-html="popupContent">
      </div>
      <div class="popup-buttons">
        <span class="cancelPopup" v-if="isCancel" @click="cancelPopup">取消</span>
        <span class="confirmPopup" @click="confirmPopup">确认</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "popup",
  props: {
    popupShow: {
      type: Boolean,
      default: false
    },
    isCancel: {
      type: Boolean,
      default: true
    },
    popupTitle: {
      type: String,
      default: ""
    },
    popupContent: {
      type: String,
      default: ""
    }
  },
  methods: {
    cancelPopup () {
      this.$emit("cancelPopup")
    },
    confirmPopup () {
      this.$emit("confirmPopup")
    }
  }
}
</script>

<style lang="scss" type="text/scss" scoped>
@import "../../styles/mixin.scss";

.popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;

  .popup-content {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 335px;
    transform: translate(-50%, -50%);
    background: #ffffff;
    border-radius: 12px;
    padding: 24px;
    box-sizing: border-box;

    .message {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
      width: 100%;
      font-size: 0;

      .title {
        font-weight: 500;
        font-size: 17px;
        color: #E84935;
        line-height: 24px;
        text-align: left;
      }

      .close {
        width: 24px;
        height: 24px;
      }

    }

    .content {
      margin-bottom: 33px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 13px;
      color: #1A1A1A;
      line-height: 20px;
    }

    .popup-buttons {
      display: flex;
      width: 100%;

      span {
        flex: 1;
        height: 40px;
        text-align: center;
        line-height: 40px;
        font-size: 16px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #FFFFFF;

        &.cancelPopup {
          background: #90D12E;
          border-radius: 12px 12px 12px 12px;
          margin-right: 20px;
        }

        &.confirmPopup {
          background: #90D12E;
          border-radius: 12px 12px 12px 12px;
        }
      }
    }
  }
}
</style>
