<template>
  <div class="searchTabs-box">
    <div class="box-item" v-for="(item,idx) in list" :key="idx" @click="handleClick(item,idx)">
      <div :class="['title',{ 'active': idx === current }]">{{ item.name }}</div>
      <div class="sort" v-if="item.isSort">
        <van-icon class="up" name="play" :color="idx === current&&item.orderBy == 3 ? '#000000' : '#666666'" size="10" />
        <van-icon class="down" name="play" :color="idx === current&&item.orderBy == 4 ? '#000000' : '#666666'" size="10" />
      </div>
      <div class="filter" v-if="item.isFilter">
        <img src="@/assets/image/search/filter.png" alt="" srcset="">
      </div>
    </div>
  </div>
</template>

<script>
import mixins from "@/utils/mixins.js"
export default {
  name: "searchTabs",
  mixins: [mixins],
  props: {

  },
  data () {
    return {
      current: 0,
      list: [
        {
          name: this.$t('热门'),
          isSort: false,
          isFilter: false,
          orderBy: 0

        },
        {
          name: this.$t('銷量'),
          isSort: false,
          isFilter: false,
          orderBy: 1
        },
        {
          name: this.$t('價格'),
          isSort: true,
          isFilter: false,
          orderBy: 4
        },
        {
          name: this.$t('篩選'),
          isSort: false,
          isFilter: true
        }
      ]
    }
  },
  methods: {
    handleClick (data, idx) {
      this.current = idx
      if (data.isSort) {
        this.list[idx].orderBy = data.orderBy == 3 ? 4 : 3
        this.$emit('change', data.orderBy)
      } else if (data.isFilter) {
        this.$emit('open')
      } else {
        this.$emit('change', data.orderBy)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.searchTabs-box {
  margin: 0 auto;
  width: 375px;
  height: 37px;
  background-color: #ffffff;
  border-bottom: 1px solid #F8F8F8;
  display: flex;
  align-items: center;

  .box-item {
    width: 94px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .title {
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #666666;
      line-height: 22px;

      &.active {
        font-weight: 500;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.9);
      }
    }


  }

  .sort {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 1px;
    overflow: hidden;

    .up {
      transform: rotate(-90deg);
    }

    .down {
      margin-top: -3px;
      transform: rotate(90deg);
    }
  }

  .filter {
    width: 16px;
    height: 16px;
    font-size: 0;

    img {
      width: 100%;
      height: 100%
    }
  }
}
</style>
