import baseURL from "@/utils/config";
import request from "@/plugins/axios";
// 商品详情app

export function getProductdetails(data,langEnv=2) {
  return request({
    url: `${baseURL.api}/api/product/getPage2`,
    method: "post",
    data,
  });
}
// 商品详情

export function getProduct(data) {
  return request({
    url: `${baseURL.api}/api/product/detail4phone?detail=1`,
    method: "post",
    data
  });
}
// 商品详情

export function getProductSpecification(data) {
  return request({
    url: `${baseURL.api}/api/product/getAllStandard2`,
    method: "post",
    data
  });
}
//立即购买创建订单
export function getProductcreateTempOrder(data) {
  return request({
    url: `${baseURL.api}/authapi/order/createTempOrder3`,
    method: "post",
    data
  });
}
//获取订单详情
export function getProductOrderDetail(data) {
  return request({
    url: `${baseURL.api}/authapi/order/getConfirmOrder2`,
    method: "post",
    data
  });
}
