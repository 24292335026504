
function getNetApiHost(values) {
	let url = "";
	const type = {
		development: 'http://test-rith-api.hansfive.com', // 开发地址
		production: 'https://api.e-rith.com',
		test: 'http://test-rith-api.hansfive.com' // 测试地址
	};
	url = type[values];
	return url;
}
function getNetJavHost(values) {
	let url = "";
	const type = {
		development: 'http://test-rith-service.hansfive.com', // 开发地址
		production: 'https://service.e-rith.com',
		test: 'http://test-rith-service.hansfive.com' // 测试地址
	};
	url = type[values];
	return url;
}
function getImageUrl(values) {
	let url = "";
	const type = {
		development: 'http://test-rith-pc.hansfive.com/upload', // 开发地址
		production: 'https://image.e-rith.com/upload',
		test: 'http://test-rith-pc.hansfive.com/upload' // 测试地址
	};
	url = type[values];
	return url;
}
export default  {
	api: getNetApiHost(process.env.NODE_ENV),
	imgUrl: getImageUrl(process.env.NODE_ENV),
	javaApi :getNetJavHost(process.env.NODE_ENV),
};
