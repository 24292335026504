import baseURL from "@/utils/config";
import request from "@/plugins/axios"

//活动列表
export function getSearchSeeDetails(data){
  return request({
      url:`${baseURL.javaApi}/api/specialEvent/seeDetails`,
      method: "post",
      data
  });
}
