<template>
  <div>
    <router-view />
<!-- 
    <draggableTab
      v-if="
        !$route.meta.hideTab &&
          !isAndroid &&
          !isiOS &&
          !whiteList.includes($route.fullPath)
      "
    ></draggableTab> -->
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      showPopover: false,
      whiteList: ["/reviewRecord"],
      isAndroid: null,
      isiOS: null
    };
  },
  mounted() {},
  created() {
    if (!localStorage.getItem("langEnv")) {
      localStorage.setItem("langEnv", "2");
    }
    document.addEventListener("gesturestart", function(event) {
      event.preventDefault();
    });
    document.addEventListener("dblclick", function(e) {
      e.preventDefault();
    });
    var u = navigator.userAgent;
    this.isAndroid =
      (u.indexOf("Android") > -1 || u.indexOf("Adr") > -1) &&
      u.indexOf("MMMMessenger") > -1; //android终端
    this.isiOS =
      !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) &&
      u.indexOf("MMMMessenger") > -1; //ios终端
  },
  computed: {
    buttonTransform() {
      return `translate(${this.buttonPositionX}px, ${this.buttonPositionY}px)`;
    }
  },
  methods: {
    onSelect(val) {},
    getToUser() {
      this.$router.push("/user/information");
    }
  }
};
</script>
<style lang="scss">
@import "./styles/base.scss";
html,
body,
#app {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background: #f8f8f8;
  -webkit-overflow-scrolling: touch;
}
#app {
  font-size: 14px;
}
</style>
