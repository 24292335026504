class MyStorage {
  
  constructor(name) {
    this.name = name
  }

  get() {
    return localStorage.getItem(this.name)
  }

  set(val) {
    return localStorage.setItem(this.name, val)
  }

  remove() {
    localStorage.removeItem(this.name)
  }
}

export default MyStorage
