export default [
  {
    path: '/searchPage',
    name: 'searchPage',
    meta: {
      hideTab:true
    },
    component: () => import('@/views/searchPage/index.vue')
  },
  {
    path: '/productList/:name',
    name: 'productList',
    meta: {
      hideTab:true
    },
    component: () => import('@/views/searchPage/productList.vue')
  }
]